<template>
  <div id="ate-basics">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <ValidationProvider
              rules="required"
              name="Term"
              v-slot="{ errors, valid }"
            >
              <b-field
                label="Term"
                :message="errors"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
              >
                <b-select
                  id="term-id"
                  v-model="ate.termId"
                  placeholder="Select a option"
                  expanded
                >
                  <option v-for="term in terms" :key="term.id" :value="term.id">
                    {{ term.title }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              name="School Class"
              v-slot="{ errors, valid }"
            >
              <b-field
                label="School Class"
                :message="errors"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
              >
                <b-select
                  id="school-class"
                  v-model="ate.schoolClassId"
                  placeholder="Select a option"
                  expanded
                >
                  <option
                    v-for="schoolClass in schoolClasses"
                    :key="schoolClass.id"
                    :value="schoolClass.id"
                  >
                    {{ schoolClass.name }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              name="Subject"
              v-slot="{ errors, valid }"
            >
              <b-field
                label="Subject"
                :message="errors"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
              >
                <b-select
                  id="subject"
                  v-model="ate.subjectId"
                  name="subject"
                  placeholder="Select a option"
                  expanded
                >
                  <option
                    v-for="subject in subjects"
                    :key="subject.id"
                    :value="subject.id"
                  >
                    {{ subject.name }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <b-field>
              <p class="control has-text-right">
                <a
                  id="submit-new-ate"
                  class="button btn-120 is-primary mt-3"
                  @click="passes(submit)"
                  :disabled="btnDisabled"
                  >Submit</a
                >
              </p>
            </b-field>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import TERMS from '../../../graphql/term/Terms.gql'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { fetchUser, fetchTerm } from '@/assets/js/app_info.js'
import { crudNotification } from '@/assets/js/notification'

export default {
  name: 'ate_basics',
  data() {
    return {
      schoolId: null,
      pageTitle: 'New ATE',
      skipAllowedAtesQuery: true,
      skipAssignedSchoolClassesQuery: true,
      allowedAtes: [],
      assignedSchoolClasses: [],
      user: null,
      term: null,
      terms: [],
      ate: {
        ateId: null,
        schoolClassId: null,
        schoolClass: null,
        subjectId: null,
        subject: null,
        name: null,
      },
      schoolClasses: [],
      subjects: [],
      ateTypes: [],
      name: '',
      error: null,
      btnDisabled: false,
    }
  },
  apollo: {
    assignedSchoolClasses: {
      query() {
        if (this.user) {
          return gql`
            query assignedSchoolClassesWithSubjectsForNewAte($id: ID!) {
              assignedSchoolClassesWithSubjects(id: $id) {
                subjectsGroupedBySchoolClass
              }
            }
          `
        }
      },
      variables() {
        return { id: this.user.id }
      },
      update: (data) => data.assignedSchoolClassesWithSubjects,
      error(error) {
        this.error = JSON.stringify(error.message)
      },
      skip() {
        return this.skipAssignedSchoolClassesQuery
      },
    },
    allowedAtes: {
      query: gql`
        query allowedAtesQuery($id: ID!) {
          allowedAtes(id: $id) {
            atesPolicy
          }
        }
      `,
      variables() {
        return { id: parseInt(this.ate.schoolClassId) }
      },
      error(error) {
        this.error = JSON.stringify(error.message)
      },
      skip() {
        return this.skipAllowedAtesQuery
      },
    },
  },
  watch: {
    terms(data) {
      this.ate.termId = data[0].id
    },
    allowedAtes(data) {
      const { fa, sa, ft, st, exams } = data.atesPolicy
      this.ateTypes = []
      if (this.schoolId == 4) {
        this.ateTypes.push(
          { code: 'fa', name: 'First Assignment', value: fa },
          { code: 'sa', name: 'Second Assignment', value: sa },
          { code: 'ft', name: 'First Test', value: ft },
          { code: 'st', name: 'Second Test', value: st },
          { code: 'aff', name: 'Skills', value: 10 },
          { code: 'exams', name: 'Exams', value: exams }
        )
      } else {
        this.ateTypes.push(
          { code: 'fa', name: 'First Assignment', value: fa },
          { code: 'sa', name: 'Second Assignment', value: sa },
          { code: 'ft', name: 'First Test', value: ft },
          { code: 'st', name: 'Second Test', value: st },
          { code: 'exams', name: 'Exams', value: exams }
        )
      }
    },
    assignedSchoolClasses(value) {
      value.subjectsGroupedBySchoolClass.forEach((data) => {
        this.schoolClasses.push({ id: data.id, name: data.name })
      })
      this.skipAssignedSchoolClassesQuery = true
    },
    'ate.schoolClassId'(id) {
      this.subjects = []

      const schoolClass = this.assignedSchoolClasses.subjectsGroupedBySchoolClass.filter(
        (schoolClass) => schoolClass.id === id
      )[0]

      if (schoolClass.number_of_students === 0) {
        this.btnDisabled = true
        this.$buefy.toast.open({
          duration: 3000,
          message: 'No students in the selected school class',
          type: 'is-info',
        })
        return
      } else {
        this.ate.schoolClassId = id

        this.skipAllowedAtesQuery = false

        schoolClass.subjects.forEach((data) => {
          this.subjects.push({ id: data.id, name: data.name })
        })

        this.btnDisabled = false
      }
    },
    'ate.subjectId'() {
      this.btnDisabled = false
    },
    'ate.name'(value) {
      if (value === 'First Assignment') {
        this.name = 'fa'
      } else if (value === 'Second Assignment') {
        this.name = 'sa'
      } else if (value === 'First Test') {
        this.name = 'ft'
      } else if (value === 'Second Test') {
        this.name = 'st'
      } else if (value === 'Affective Domain') {
        this.name = 'aff'
      } else if (value === 'Exams') {
        this.name = 'exams'
      }
      this.btnDisabled = false
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    submit() {
      this.btnDisabled = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createAteQuery(
              $term_id: Int!
              $user_id: Int!
              $subject_id: Int!
              $school_class_id: Int!
            ) {
              createAte(
                input: {
                  termId: $term_id
                  userId: $user_id
                  subjectId: $subject_id
                  schoolClassId: $school_class_id
                }
              ) {
                ate {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            user_id: this.user.id,
            term_id: parseInt(this.ate.termId),
            subject_id: parseInt(this.ate.subjectId),
            school_class_id: parseInt(this.ate.schoolClassId),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.createAte.errors,
            'Successfully created.'
          )
            .then(() => {
              this.$router.push(`/school/${this.schoolId}/ate_unmarked`)
            })
            .catch((error) => {
              // console.error(error)
              this.btnDisabled = false
            })
        })
        .catch((error) => {
          console.error(error)
          this.btnDisabled = false
        })
    },
  },
  mounted() {
    this.schoolId = this.$route.params.school_id
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchUser().then((user) => {
      this.user = user
      this.skipAssignedSchoolClassesQuery = false
    })

    fetchTerm().then((term) => {
      this.term = term
    })

    this.$store.commit('setSubMenus', [
      {
        name: 'Ate Unmarked',
        route: `/school/${this.schoolId}/ate_unmarked`,
      },
    ])

    this.$apollo.addSmartQuery('terms', {
      query: TERMS,
      variables: {
        schoolId: parseInt(this.schoolId),
      },
    })

    this.$apollo.queries.terms.refetch()
  },
}
</script>

<style scoped></style>
